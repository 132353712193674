<template>
  <div>
    <v-list v-if="$vuetify.breakpoint.xs">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :to="{
          name: 'SchoolClassRegister',
          params: { id: item.id },
        }"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.code }}</v-list-item-title>
          <v-list-item-subtitle
            >{{ item.majorSubjects.join(", ") }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <PersonItem small :value="item.teacher"> </PersonItem>
      </v-list-item>
    </v-list>
    <v-data-table
      v-else
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
      :headers="headers"
      :items="items"
      :item-class="() => 'clickable'"
      @click:row="
        (item) =>
          $router.push({
            name: 'SchoolClassRegister',
            params: {
              id: item.id,
            },
          })
      "
    >
      <template v-slot:item.teacher="{ item }">
        <PersonItem small :value="item.teacher"> </PersonItem>
      </template>
      <template v-slot:item.substituteTeacher="{ item }">
        <PersonItem small :value="item.substituteTeacher"> </PersonItem>
      </template>
      <template v-slot:item.bilingual="{ item }">
        {{ item.bilingual.join(", ") }}
      </template>
      <template v-slot:item.majorSubjects="{ item }">
        {{ item.majorSubjects.join(", ") }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem.vue";

export default defineComponent({
  name: "SchoolClassItems",
  components: { PersonItem },
  data() {
    return {
      headers: [
        {
          text: "",
          align: "start",
          value: "code",
        },
        { text: "KL", value: "teacher" },
        { text: "Stv.", value: "substituteTeacher" },
        { text: "BI", value: "bilingual" },
        { text: "SF", value: "majorSubjects" },
        { text: "Anz.", value: "studentCount.total" },
      ],
    };
  },
  props: ["items"],
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
});
</script>
