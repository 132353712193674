<template>
  <Page color="info" title="Klassen">
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <v-spacer></v-spacer>
        <v-text-field
          class="mr-2"
          clearable
          placeholder="Klasse (g, 28, m27, w26a, ...), Kürzel"
          v-model="search"
          hide-details
          dense
        ></v-text-field>
        <v-btn outlined text @click="showMine()">meine</v-btn>
      </v-row>
    </v-container>

    <v-card v-for="(item, index) in items" :key="index" class="mb-4">
      <v-system-bar>{{ item.description }}</v-system-bar>
      <SchoolClassSearchItems :items="item.items" />
    </v-card>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import { debounce } from "lodash";
import SchoolClassSearchItems from "./SchoolClassSearchItems.vue";
import { searchPerson } from "common/utils/people.js";

export default defineComponent({
  name: "SchoolClasses",
  components: { SchoolClassSearchItems },
  data() {
    return {
      abortController: null,
      loading: false,
      search: "",
      items: [],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    itemsClassTeacher() {
      return this.items.filter(
        (el) => this.search.length >= 3 && searchPerson(el.teacher, this.search)
      );
    },
    itemsClassSubstituteTeacher() {
      return this.items.filter(
        (el) =>
          this.search.length >= 3 &&
          searchPerson(el.substituteTeacher, this.search)
      );
    },
    itemsCurrentTeacher() {
      return this.items.filter(
        (el) =>
          this.search.length >= 3 &&
          el.currentTeachers.some((el) => searchPerson(el, this.search))
      );
    },
    itemsCode() {
      return this.items.filter((el) =>
        el.code.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  watch: {
    search() {
      localStorage.setItem("school_class_search", this.search);
      this.fetchData();
      if (
        !this.$route.query.search ||
        this.$route.query.search != this.search
      ) {
        this.$router.replace({ query: { search: this.search } });
      }
    },
  },
  methods: {
    fetchData: debounce(async function () {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      this.loading = true;
      if (!this.search) this.search = "";
      const items = await this.apiList({
        resource: "schoolclass/schoolclass",
        query: `search=${this.search}`,
        signal: this.abortController.signal,
      });

      this.items = items;
      this.loading = false;
    }, 300),
    showMine() {
      this.search = this.$_profilePerson.code;
    },
  },
  created() {
    if (!this.$_hasRole("student")) {
      if (this.$route.query.search) {
        this.search = this.$route.query.search;
      } else if (localStorage.getItem("school_class_search")) {
        this.search = localStorage.getItem("school_class_search");
      }
    }
    this.fetchData();
  },
});
</script>
