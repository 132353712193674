var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.$vuetify.breakpoint.xs)?_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":{
        name: 'SchoolClassRegister',
        params: { id: item.id },
      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.code))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.majorSubjects.join(", "))+" ")])],1),_c('PersonItem',{attrs:{"small":"","value":item.teacher}})],1)}),1):_c('v-data-table',{attrs:{"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-class":() => 'clickable'},on:{"click:row":(item) =>
        _vm.$router.push({
          name: 'SchoolClassRegister',
          params: {
            id: item.id,
          },
        })},scopedSlots:_vm._u([{key:"item.teacher",fn:function({ item }){return [_c('PersonItem',{attrs:{"small":"","value":item.teacher}})]}},{key:"item.substituteTeacher",fn:function({ item }){return [_c('PersonItem',{attrs:{"small":"","value":item.substituteTeacher}})]}},{key:"item.bilingual",fn:function({ item }){return [_vm._v(" "+_vm._s(item.bilingual.join(", "))+" ")]}},{key:"item.majorSubjects",fn:function({ item }){return [_vm._v(" "+_vm._s(item.majorSubjects.join(", "))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }